<template>
  <StMBanners
    v-if="ratRaceWidget"
    class="casino-banners"
    :items="banners"
    :is-mini="isMini"
    :is-loading="isLoading"
    size="l"
    :gap="6"
  >
    <template #preBanner>
      <StSliderItem class="item">
        <MTournamentMenuItem :card="ratRaceWidget" :is-mini="isMini" size="l" />
      </StSliderItem>
    </template>
  </StMBanners>
  <StMBanners
    v-else
    :is-loading="isLoading"
    :items="banners"
    :is-mini="isMini"
    size="l"
  />
</template>

<script setup lang="ts">
import StMBanners from '@st/ui/components/StMBanners/StMBanners.vue'
import MTournamentMenuItem from '@st/tournaments/components/TournamentMenuItem/MTournamentMenuItem.vue'

const { banners, isLoading } = useBanners('mobileCasino')

defineProps<{
  isMini?: boolean
}>()

const { tournament: ratRaceWidget } = usePageTournament('casino')
</script>
<style scoped>
.casino-banners {
  margin-top: var(--spacing-150);
  margin-bottom: var(--spacing-100);
}
</style>
